import React from 'react';
import { Switch, Route } from 'react-router-dom';

import 'highlight.js/styles/dracula.css';

import Sidebar from './Sidebar/Sidebar';
import DocContainer from './DocContainer/DocContainer';
import DocsThemeChanger from '../helpers/DocsThemeChanger';


import {
    ReactAux, Icon, Collapse, Header, Button, Layout, Input, Textarea,
    Select, Tabs, Checkbox, Radio, LeftSideBar, Typography, Tooltip as TooltipDocs,
    LinkWithIcon, ActivityChecklist, WizardStepsContainer, ActionContainer, InfoBox,
    Card, LoanOptionCard, ProgressBar, Loader, Introduction, StyleUtils, Grid, Testing, FormExamples,
    WidgetContainer, SimpleTableContainer, SummaryBox, WorkQueue, RadialGugeResult, ThemeChanger,
    PortalHeader, NumericInput, MaskInput, ValidationMessage, Modal, DragNDrop, Widths,
    Autocomplete, Datepicker, Multiselect, MultiselectBox, MultiEmail, Table, FileUpload, Diagram,
    Dropzone, Toast, Toolbar, ContextMenu, ConfirmationDialog, HelpBox,
    DrillDown, Label, Popover, Badge, UploadedFileRow, Chat, ShowImage, ButtonGroup, GitFlow,
    Carousel, CardVertical, Editor, CardList, NumericInputSpinner, ErrorStatus, ResizableSections,
    Toggle, Timeline, QueryBuilder, DynamicScrollableContainer, FileUploadOld, Stack, TreeView,
    Slider, ColorPicker, ShadePicker,ImageCard, Notification, ScheduleButton, Container,
    EllipsisWithTooltip
} from './pages';

class Docs extends React.Component {

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        const body = document.getElementsByTagName('body');
        body[0].className += ' docs-body';
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body');
        body[0].className = 'lv-ds-beta';
    }

    render() {
        return (
            <div>
                <Sidebar />
                <DocContainer>
                    <Switch>
                        <Route path="/docs/action-container" component={ActionContainer} />
                        <Route path="/docs/activity-checklist" component={ActivityChecklist} />
                        <Route path="/docs/button" component={Button} />
                        <Route path="/docs/checkbox" component={Checkbox} />
                        <Route path="/docs/color-picker" component={ColorPicker} />
                        <Route path="/docs/shade-picker" component={ShadePicker} />
                        <Route path="/docs/react-aux" component={ReactAux} />
                        <Route path="/docs/resizable-sections" component={ResizableSections} />
                        <Route path="/docs/icon" component={Icon} />
                        <Route path="/docs/link-with-icon" component={LinkWithIcon} />
                        <Route path="/docs/input" component={Input} />
                        <Route path="/docs/mask-input" component={MaskInput} />
                        <Route path="/docs/radio" component={Radio} />
                        <Route path="/docs/select" component={Select} />
                        <Route path="/docs/slider" component={Slider} />
                        <Route path="/docs/editor" component={Editor} />
                        <Route path="/docs/textarea" component={Textarea} />
                        <Route path="/docs/collapse" component={Collapse} />
                        <Route path="/docs/header" component={Header} />
                        <Route path="/docs/datepicker" component={Datepicker} />
                        <Route path="/docs/tabs" component={Tabs} />
                        <Route path="/docs/timeline" component={Timeline} />
                        <Route path="/docs/layout" component={Layout} />
                        <Route path="/docs/left-side-bar" component={LeftSideBar} />
                        <Route path="/docs/typography" component={Typography} />
                        <Route path="/docs/tooltip" component={TooltipDocs} />
                        <Route path="/docs/simple-table-container" component={SimpleTableContainer} />
                        <Route path="/docs/table" component={Table} />
                        <Route path="/docs/summary-box" component={SummaryBox} />
                        <Route path="/docs/radialgauge-result" component={RadialGugeResult} />
                        <Route path="/docs/widget-container" component={WidgetContainer} />
                        <Route path="/docs/wizard-steps-container" component={WizardStepsContainer} />
                        <Route path="/docs/info-box" component={InfoBox} />
                        <Route path="/docs/card" component={Card} />
                        <Route path="/docs/card-vertical" component={CardVertical} />
                        <Route path="/docs/card-list" component={CardList} />
                        <Route path="/docs/introduction" component={Introduction} />
                        <Route path="/docs/loan-option-card" component={LoanOptionCard} />
                        <Route path="/docs/progress-bar" component={ProgressBar} />
                        <Route path="/docs/loader" component={Loader} />
                        <Route path="/docs/style-utils" component={StyleUtils} />
                        <Route path="/docs/grid" component={Grid} />
                        <Route path="/docs/testing" component={Testing} />
                        <Route path="/docs/form-examples" component={FormExamples} />
                        <Route path="/docs/work-queue" component={WorkQueue} />
                        <Route path="/docs/theme-changer" component={ThemeChanger} />
                        <Route path="/docs/portal-header" component={PortalHeader} />
                        <Route path="/docs/numeric-input" component={NumericInput} />
                        <Route path="/docs/numeric-input-spinner" component={NumericInputSpinner} />
                        <Route path="/docs/validation-message" component={ValidationMessage} />
                        <Route path="/docs/modal" component={Modal} />
                        <Route path="/docs/notification" component={Notification} />
                        <Route path="/docs/diagram" component={Diagram} />
                        <Route path="/docs/drag-n-drop" component={DragNDrop} />
                        <Route path="/docs/widths" component={Widths} />
                        <Route path="/docs/autocomplete" component={Autocomplete} />
                        <Route path="/docs/multiselect" component={Multiselect} />
                        <Route path="/docs/multiselect-box" component={MultiselectBox} />
                        <Route path="/docs/multi-email" component={MultiEmail} />
                        <Route path="/docs/file-upload" component={FileUpload} />
                        <Route path="/docs/file-upload-old" component={FileUploadOld} />
                        <Route path="/docs/dropzone" component={Dropzone} />
                        <Route path="/docs/toast" component={Toast} />
                        <Route path="/docs/toolbar" component={Toolbar} />
                        <Route path="/docs/context-menu" component={ContextMenu} />
                        <Route path="/docs/confirmation-dialog" component={ConfirmationDialog} />
                        <Route path="/docs/help-box" component={HelpBox} />
                        <Route path="/docs/drill-down" component={DrillDown} />
                        <Route path="/docs/label" component={Label} />
                        <Route path="/docs/popover" component={Popover} />
                        <Route path="/docs/badge" component={Badge} />
                        <Route path="/docs/uploaded-file-row" component={UploadedFileRow} />
                        <Route path="/docs/chat" component={Chat} />
                        <Route path="/docs/show-image" component={ShowImage} />
                        <Route path="/docs/button-group" component={ButtonGroup} />
                        <Route path="/docs/git-flow" component={GitFlow} />
                        <Route path="/docs/carousel" component={Carousel} />
                        <Route path="/docs/error-status" component={ErrorStatus} />
                        <Route path="/docs/toggle" component={Toggle} />
                        <Route path="/docs/tree-view" component={TreeView} />
                        <Route path="/docs/query-builder" component={QueryBuilder} />
                        <Route path="/docs/dynamic-scrollable-container" component={DynamicScrollableContainer} />
                        <Route path="/docs/stack" component={Stack} />
                        <Route path="/docs/image-card" component={ImageCard} />
                        <Route path="/docs/schedule-button" component={ScheduleButton} />
                        <Route path="/docs/container" component={Container} />
                        <Route path="/docs/ellipsis-with-tooltip" component={EllipsisWithTooltip} />
                    </Switch>
                </DocContainer>
                <DocsThemeChanger />
            </div>
        );
    }
}

export default Docs;
