import React from 'react';
import Highlight from 'react-highlight';

import { EllipsisWithTooltip } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const LabelPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">EllipsisWithTooltip</h1>
            <p className="mb-l">Component that truncates overflowing text with an ellipsis and displays the full content in a tooltip when hovered over.</p>

            <div style={{ width: '150px' }}>
                <EllipsisWithTooltip className="mb-s" tooltipText="VeryyyyyyLoooooongText" dataUI={getDataUI()}>VeryyyyyyLoooooongText</EllipsisWithTooltip>
                <EllipsisWithTooltip tooltipText="Short text" dataUI={getDataUI()}>Short text</EllipsisWithTooltip>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { EllipsisWithTooltip } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
        <div style={{ width: '150px' }}>
            <EllipsisWithTooltip tooltipText="VeryyyyyyLoooooongText" dataUI={getDataUI()}>VeryyyyyyLoooooongText</EllipsisWithTooltip>
            <EllipsisWithTooltip tooltipText="Short text" dataUI={getDataUI()}>Short text</EllipsisWithTooltip>
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>tooltipText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set text that will be display as tooltip.</td>
                    </tr>
                    <tr>
                        <td>tooltip</td>
                        <td>TooltipProps</td>
                        <td className='text-center'>-</td>
                        <td>Set component tooltip config.</td>
                    </tr>
                    <tr>
                        <td>all native "p" attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native "p" attributes like: "onClick", "id", etc.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default LabelPage;
